import React from 'react';
import './details.css'

import SupportForm from '../../components/SupportForm'
import { Grid, Stack } from '@mui/material'
import { floors } from '../../utils/utils'

// icons
import area from '../../assets/icons/size.svg'
import money from '../../assets/icons/money.svg'
import doubl_floor from '../../assets/icons/double floor.svg'
import shop from '../../assets/icons/Shop.svg'
import ImageViewer from '../../components/ImageViewer';
import { Helmet } from 'react-helmet-async';


export default function Details() {
    const [img, setImg] = React.useState(area);
    const [open, setOpen] = React.useState(false);
    const handleOpen = (img) => {
        setOpen(true)
        setImg(img)
    }




    return (
        <div>
            {/* Start SEO with helmet */}
            <Helmet>
                <meta name="description" content="تفاصيل المول | مول راكان. اكتشف المزيد عن هذا المول ومنتجاته وخدماته المميزة." />
                <meta name="keywords" content="تفاصيل المول, منتجات, خدمات, مول راكان" />
                <link rel="canonical" href="/details" />
                <title>التفاصيل | مول راكان</title>
            </Helmet>
            {/* End SEO with helmet */}

            <Stack className='details-hero-sec' />
            {floors.map((f, i) => (
                <Stack key={i} className='floor container' sx={{ backgroundColor: f == floors[1] && '#FBFBFB' }}>
                    <Grid container>
                        <Grid xs={12} md={6}>
                            <Stack direction='column' spacing={3}>
                                <h2>{f.floor}</h2>
                                <Stack direction='row' alignItems='center'>
                                    <img width='32' src={shop} alt="" />
                                    <p style={{ margin: '0 16px' }}>عدد الوحدات {f.unitsCount} وحدة</p>
                                </Stack>
                                <Stack direction='row' alignItems='center'>
                                    <img width='32' src={area} alt="" />
                                    <p style={{ margin: '0 16px' }}>مساحة الوحدات تبدأ {f.area}</p>
                                </Stack>
                                {f.id == 1 && <Stack direction='row' alignItems='center'>
                                    <img width='32' src={doubl_floor} alt="" />
                                    <p style={{ margin: '0 16px' }}>متاح وحدات بميزانين</p>
                                </Stack>}
                                <Stack direction='row' alignItems='center'>
                                    <img width='32' src={money} alt="" />
                                    <p style={{ margin: '0 16px' }}>مقدمات تبدأ من {f.downPayment} جنيه</p>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid xs={12} md={6} p={3} container>
                            <Grid xs={12} md={f.image2 ? 6 : 12}>
                                <img style={{ cursor: 'pointer' }} onClick={() => handleOpen(f.image)} width='90%' src={f.image} alt="image" />
                            </Grid>
                            {f.image2 && <Grid xs={12} md={6}>
                                {f.image2 && <img style={{ cursor: 'pointer' }} onClick={() => handleOpen(f.image2)} width='90%' src={f.image2} alt="image" />}
                            </Grid>}
                            <ImageViewer image={img} open={open} setOpen={setOpen} />
                        </Grid>
                    </Grid>
                </Stack>
            ))}

            <SupportForm />
        </div>
    )
}
