import { Stack } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export default function Page404() {
    return (

        <Stack sx={{ height: '500px' }} direction='column' gap={3} alignItems={'center'} justifyContent={'center'} >
            <h1>لا يوجد صفحة بهذا الرابط | خطأ 404</h1>
            <Link to={'/'}>العودة للصفحة الرئيسية</Link>
        </Stack>
    )
}
