import './App.css';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar'
import Home from './pages/home/Home';
import Details from './pages/details/Details';
import Contact from './pages/contact/Contact';
import Footer from './components/Footer';
import { Stack } from '@mui/material';
import Gallery from './pages/gallery/Gallery';
import Page404 from './pages/404/Page404';


function App() {
  return (
    <div style={{ overflow: 'hidden' }}>
      <Navbar />
      <Routes>
        <Route path='/' Component={Home} />
        <Route path='/details' Component={Details} />
        <Route path='/gallery' Component={Gallery} />
        <Route path='/contact' Component={Contact} />
        <Route path='*' Component={Page404} />

      </Routes>
      {/* {component} */}
      <Stack className='footer-sec bg-primary'>
        <Footer />
      </Stack>
    </div>
  );
}

export default App;
