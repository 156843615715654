import React from 'react'
import './home.css'
import { Button, Grid, Stack, Box } from '@mui/material'
import AboutImg from '../../assets/images/about img.svg'
import plazaImg from '../../assets/images/plaza.jpg'
import card2Img from '../../assets/images/card2.jpg'
import card3Img from '../../assets/images/card3.jpg'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import SupportForm from '../../components/SupportForm';

import StoreIcon from '@mui/icons-material/Store';
import StairsIcon from '@mui/icons-material/Stairs';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import PhoneIcon from '@mui/icons-material/Phone';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PinDropRoundedIcon from '@mui/icons-material/PinDropRounded';


import { services } from '../../utils/utils'
import { Helmet } from 'react-helmet-async'

export default function Home() {
    return (
        <div>
            {/* Start SEO with helmet */}
            <Helmet>
                <meta name="description" content="استثمر في محلك التجاري في راكان مول واحصل على فرصة رائعة لعرض منتجاتك وخدماتك." />
                <meta name="keywords" content="مول راكان, راكان مول, محلات للبيع, محلات للإيجار, محلات تجارية, تسوق في راكان مول, محلات تجارية مميزة, فرص استثمارية, تجارة وأعمال" />
                <link rel="canonical" href="/" />
                <title>راكان مول | الرئيسية</title>
            </Helmet>
            {/* End SEO with helmet */}




            <Stack className="hero-sec container" justifyContent='center' >
                <Stack className="text-box" justifyContent='space-between'>
                    <Stack spacing={2}>
                        <h2>راكان مول</h2>
                        <p>إبدأ مشروعك الان و استثمر في انشطة و مساحات مختلفة وسط اعلي كثافة سكانية بأنظمة سداد متعددة بدون فوائد</p>
                        <p>احدث مشروع تجاري إداري طبي بأفضل موقع بالقرب من اهم الطرق و المحاور و الخدمات الاستراتيجية بمدينة الخمائل - مدخل زايد بجوار مسجد الشرطة امام هايبر وان</p>
                    </Stack>
                    <Button component={Link} to='/contact' className='home-btn'>تواصل معنا</Button>
                </Stack>
            </Stack>

            <Stack className='about-sec container'>
                <Grid container>
                    <Grid item xs={12} md={5}>
                        <h5 className='sub-heading'>نبذة عن راكان مول</h5>
                        <h2 className='main-heading'>اكبر مول تجاري اداري طبي بمدينة الخمائل</h2>

                        <Stack direction='column' spacing={2} pt={3}>
                            <Stack direction='row' alignItems='center'>
                                <AspectRatioIcon fontSize='large' className='color-primary' />
                                <p style={{ marginRight: '16px' }}>مساحة المول: 4250 متر </p>
                            </Stack>
                            <Stack direction='row' alignItems='center'>
                                <StairsIcon fontSize='large' className='color-primary' />
                                <p style={{ marginRight: '16px' }}>عدد الادوار: 3 ادوار</p>
                            </Stack>
                            <Stack direction='row' alignItems='center'>
                                <StoreIcon fontSize='large' className='color-primary' />
                                <p style={{ marginRight: '16px' }}> عدد الوحدات: 104 وحدة </p>
                            </Stack>
                            <Box pt={3}>
                                <Button component={Link} to='/details' className='color-primary' endIcon={<KeyboardBackspaceIcon sx={{ marginRight: '16px' }} />}>معلومات إضافية</Button>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid xs={2}></Grid>
                    <Grid item xs={12} md={5}>
                        <img width='100%' src={AboutImg} alt="image" />
                    </Grid>
                </Grid>
            </Stack>


            <Stack className='services-sec container bg-secondary' alignItems='center'>
                <h2 className='main-heading'>خدماتنا</h2>
                <Grid mt={3} container spacing={2} rowSpacing={4} justifyContent='center' sx={{ textAlign: 'center' }}>
                    {services.map(s => (
                        <Grid key={s.title} item xs={3} md={2} >
                            <Stack>
                                <Stack direction='column' sx={{ width: { xs: '32px', md: '40px' }, margin: 'auto' }} alignItems='center'>
                                    <img width={'100%'} src={s.icon} alt="icon" />
                                </Stack>
                                <h5>{s.title}</h5>
                            </Stack>
                        </Grid>
                    ))}

                </Grid>
            </Stack>

            <Stack className='best-recommendation-sec container'>
                <Stack mb={3} direction='row' justifyContent='space-between' alignItems='center'>
                    <h2 className='main-heading'>وحدات مميزة</h2>
                    <Link to='/details' className='row color-primary'>المزيد <ChevronLeftRoundedIcon /></Link>
                </Stack>
                <Grid container columnSpacing={10} rowSpacing={3} justifyContent='center'>
                    <Grid item xs={12} sm={6} lg={4} >
                        <Card img={plazaImg} floor='الارضي' space='17.5' height='6' downpayment='485.000' />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Card img={card2Img} floor='الاول' space='35' height='3' downpayment='454.000' />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} >
                        <Card img={card3Img} floor='الثاني' space='35' height='3' downpayment='414.000' />
                    </Grid>
                </Grid>
            </Stack>

            <Stack className='contact-sec container bg-secondary'>
                <h2 className='main-heading'>تواصل معنا</h2>
                <Grid container mt={3}>
                    <Grid item xs={12} md={4}>
                        <p style={{ textAlign: 'center' }}>راكان مول</p>
                        <iframe width="95%" height="230" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3454.084962526328!2d31.037484476105146!3d30.03442027492866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14585b5e6d9ba9f5%3A0xaaf33308015f8a21!2z2LHYp9mD2KfZhiDZhdmI2YQgLSBSYWthbiBNYWxs!5e0!3m2!1sen!2seg!4v1688826745041!5m2!1sen!2seg" frameborder="0"></iframe>

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <p style={{ textAlign: 'center' }}>مكتب راكان مول</p>
                        <iframe width="95%" height="230" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3453.825292033727!2d30.966579315115126!3d30.041869681883234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDAyJzMwLjciTiAzMMKwNTgnMDcuNiJF!5e0!3m2!1sen!2seg!4v1689302572004!5m2!1sen!2seg" frameborder="0"></iframe>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Stack spacing={2} direction='column'>
                            <h3>تواصل معنا للإستفسار عن راكان مول <br /> و لإيجاد الوحدة المناسبة لعملك</h3>
                            <Stack alignItems='center' spacing={1} direction='row'>
                                <EmailRoundedIcon className='color-primary' />
                                <p style={{ marginRight: '16px' }}>info@rakan-mall.com</p>
                            </Stack>
                            <Stack alignItems='center' spacing={1} direction='row'>
                                <PhoneIcon className='color-primary' />
                                <Grid container sx={{ direction: { xs: 'column', md: 'row' } }}>
                                    <Grid item xs={12} sm={4} md={12} lg={4}><span style={{ marginRight: '16px' }}>01004144412</span></Grid>
                                    <Grid item xs={12} sm={4} md={12} lg={4}><span style={{ marginRight: '16px' }}>01289938888</span></Grid>
                                    <Grid item xs={12} sm={4} md={12} lg={4}><span style={{ marginRight: '16px' }}>01015005950</span></Grid>
                                </Grid>
                            </Stack>
                            <Stack alignItems='center' spacing={1} direction='row'>
                                <PinDropRoundedIcon className='color-primary' />
                                <p style={{ marginRight: '16px' }}>عنوان المول: مدينة الخمائل - خلف مسجد الشرطة - الشيخ زايد - الجيزة - مصر</p>
                            </Stack>
                            <Stack alignItems='center' spacing={1} direction='row'>
                                <PinDropRoundedIcon className='color-primary' />
                                <p style={{ marginRight: '16px' }}>عنوان المكتب: فيلا 52 الحى الحادى عشر بشارع القسم والمرور أمام الحى العاشر</p>
                            </Stack>
                            <Button component={Link} to='/contact' className='btn-primary'>تواصل معنا</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>



            <SupportForm />
        </div>
    )
}
