// images
import floor1 from '../assets/images/floor1.jpg'
import double_floor from '../assets/images/double-floor.jpg'
import floor2 from '../assets/images/floor2.jpg'
import floor3 from '../assets/images/floor3.jpg'

// Icons
import cameraIcon from '../assets/icons/camera.svg'
import foodIcon from '../assets/icons/food.svg'
import securityIcon from '../assets/icons/Security.svg'
import atmIcon from '../assets/icons/atm.svg'
import wifiIcon from '../assets/icons/wifi.svg'
import conditioningIcon from '../assets/icons/conditioning.svg'
import toolsIcon from '../assets/icons/Tools.svg'
import toiletIcon from '../assets/icons/Toilet.svg'
import hygieneIcon from '../assets/icons/hygiene.svg'
import fireAlarmIcon from '../assets/icons/Fire Alarm.svg'
import gateIcon from '../assets/icons/gate.svg'
import storageIcon from '../assets/icons/storage.svg'
import elevatorIcon from '../assets/icons/Elevator.svg'
import fountainIcon from '../assets/icons/Fountain.svg'

export const services = [
    { icon: cameraIcon, title: 'كاميرات مراقبة' },
    { icon: foodIcon, title: 'صالة طعام' },
    { icon: securityIcon, title: 'أمن' },
    { icon: atmIcon, title: 'ماكينات ATM' },
    { icon: wifiIcon, title: 'واي فاي مجاني' },
    { icon: conditioningIcon, title: 'مكييفات' },
    { icon: toolsIcon, title: 'صيانة' },
    { icon: toiletIcon, title: 'حمامات' },
    { icon: hygieneIcon, title: 'عمال نظافة' },
    { icon: fireAlarmIcon, title: 'انذارات حريق' },
    { icon: gateIcon, title: 'بوابات' },
    { icon: storageIcon, title: 'مساحات للتخزين' },
    { icon: elevatorIcon, title: 'مصاعد كهربائية' },
    { icon: fountainIcon, title: 'نافورة' },
]


export const floors = [
    {
        id: 1,
        floor: 'الدور الارضي',
        unitsCount: 41,
        area: 'من 17 متر وتصل إلي 200 متر',
        downPayment: '485.000',
        image: floor1,
        image2: double_floor,
    },
    {
        id: 2,
        floor: 'الدور الاول',
        unitsCount: 32,
        area: 'من 35 متر وتصل إلي 300 متر',
        downPayment: '454.000',
        image: floor2
    },
    {
        id: 3,
        floor: 'الدور الثاني',
        unitsCount: 32,
        area: 'من 35 متر وتصل إلي 300 متر',
        downPayment: '414.000',
        image: floor3
    }
]