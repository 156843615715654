import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser';

import './contact.css'
import { Button, Grid, Stack } from '@mui/material'
import logo from '../../assets/images/logo.svg'
import PhoneIcon from '@mui/icons-material/Phone';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PinDropRoundedIcon from '@mui/icons-material/PinDropRounded';
import Input from '../../components/Input';
import ContactSuccess from '../../components/ContactSuccess';
import { Helmet } from 'react-helmet-async';

export default function Contact() {
    const form = useRef();
    const [done, setDone] = useState(false)

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_dgm25i1', 'template_ncdmxgj', form.current, '9WYZI5IjkfLExS11f')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        setDone(true)
    };


    return (
        <>
            {done ? <ContactSuccess /> :
                <div className='contact-page container'>
                    {/* Start SEO with helmet */}
                    <Helmet>
                        <meta name="description" content="اتصل بنا للاستفسارات والاستثمار في محلات راكان مول. نحن هنا لمساعدتك والرد على استفساراتك." />
                        <meta name="keywords" content="اتصل بنا, راكان مول, استفسارات, استثمار, تواصل, محلات تجارية" />
                        <link rel="canonical" href="/contact" />
                        <title>اتصل بنا | مول راكان</title>
                    </Helmet>
                    {/* End SEO with helmet */}

                    <Grid container>
                        <Grid item xs={12} md={5}>
                            <Stack alignItems='center'>
                                <img width='60%' src={logo} alt="" />
                            </Stack>
                            <Stack spacing={2} sx={{ borderTop: '3px solid #F79E02', padding: '24px 0' }} direction='column'>
                                <Stack alignItems='center' spacing={1} direction='row'>
                                    <EmailRoundedIcon className='color-primary' />
                                    <span style={{ marginRight: '16px' }}>info@rakan-mall.com</span>
                                </Stack>
                                <Stack sx={{ width: '100%' }} alignItems='center' spacing={1} direction='row'>
                                    <PhoneIcon className='color-primary' />
                                    <Grid container sx={{ direction: { xs: 'column', md: 'row' } }}>
                                        <Grid item xs={12} sm={4}><span style={{ marginRight: '16px' }}>01004144412</span></Grid>
                                        <Grid item xs={12} sm={4}><span style={{ marginRight: '16px' }}>01289938888</span></Grid>
                                        <Grid item xs={12} sm={4}><span style={{ marginRight: '16px' }}>01015005950</span></Grid>
                                    </Grid>
                                </Stack>
                                <Stack alignItems='center' spacing={1} direction='row'>
                                    <PinDropRoundedIcon className='color-primary' />
                                    <p style={{ marginRight: '16px' }}>عنوان المول: مدينة الخمائل - خلف مسجد الشرطة - الشيخ زايد - الجيزة - مصر</p>
                                </Stack>
                                <Stack alignItems='center' spacing={1} direction='row'>
                                    <PinDropRoundedIcon className='color-primary' />
                                    <p style={{ marginRight: '16px' }}>عنوان المكتب: فيلا 52 الحى الحادى عشر بشارع القسم والمرور أمام الحى العاشر</p>
                                </Stack>
                            </Stack>

                        </Grid>
                        <Grid xs={12} md={2} />
                        <Grid item xs={12} md={5}>
                            <form ref={form} onSubmit={sendEmail}>
                                <Stack spacing={2}>
                                    <Input label='الاسم' type='text' name='user_name' />
                                    <Input label='الهاتف' type='text' name='user_phone' />
                                    <Input label='العنوان' type='text' name='msg_subject' />
                                    <Input label='الرسالة' type='text' name='message' />
                                    <Button className='btn-primary' type='submit'>إرسال</Button>
                                </Stack>
                            </form>
                        </Grid>
                    </Grid>
                </div>
            }
        </>
    )
}
