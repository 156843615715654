import React from 'react'
import './footer.css'
import logo from '../assets/images/logo.svg'
import { IconButton, Stack } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

import { Link } from 'react-router-dom';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';



export default function Footer() {
    return (
        <Stack m={2} direction='column' alignItems='center' justifyContent='center' spacing={2}>
            <div className="logo"><img height='150px' src={logo} alt="" /></div>
            <Stack direction='row'>
                <Link to='http://www.instagram.com/rakanmall.eg' target="blanket">
                    <IconButton size='large' sx={{ color: 'black' }}> <InstagramIcon sx={{ fontSize: '32px' }} /> </IconButton>
                </Link>
                <Link to='http://wa.me/+201289938888' target="blanket">
                    <IconButton size='large' sx={{ color: 'black' }}> <WhatsAppIcon sx={{ fontSize: '32px' }} /> </IconButton>
                </Link>
                <Link to='https://www.facebook.com/RakanMallEg' target="blanket">
                    <IconButton size='large' sx={{ color: 'black' }}> <FacebookIcon sx={{ fontSize: '32px' }} /> </IconButton>
                </Link>
                <Link to='https://twitter.com/RakanMallEg' target="blanket">
                    <IconButton size='large' sx={{ color: 'black' }}> <TwitterIcon sx={{ fontSize: '32px' }} /> </IconButton>
                </Link>

            </Stack>
            <span>جميع الحقوق محفوظة لراكان مول © 2023</span>
        </Stack>
    )
}
