import { Button, Stack } from '@mui/material'
import React from 'react'
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import StairsIcon from '@mui/icons-material/Stairs';
import CardImg from '../assets/images/card img.svg'
import money from '../assets/icons/money.svg'
import { Link } from 'react-router-dom';

export default function Card({ img, floor, space, downpayment, height }) {
    return (
        <Stack className='card'>
            <Stack> <img width='100%' style={{ height: '220px' }} src={img} alt="image" /></Stack>
            <Stack p={3} spacing={2}>
                <h4 style={{ textAlign: 'center' }}>الدور {floor}</h4>
                <Stack direction='row' justifyContent='space-between'>
                    <Stack alignItems='center' className='color-primary' direction='column'>
                        <AspectRatioIcon />
                        <p style={{ textAlign: 'center', fontSize: '14px' }}>المساحة: {space} متر</p>
                    </Stack>
                    <Stack alignItems='center' className='color-primary' direction='column'>
                        <StairsIcon />
                        <p style={{ textAlign: 'center', fontSize: '14px' }}>الارتفاع: {height} متر</p>
                    </Stack>
                    <Stack alignItems='center' className='color-primary' direction='column'>
                        <img height='24' src={money} alt="money icon" />
                        <p style={{ textAlign: 'center', fontSize: '14px' }}>مقدم {downpayment} جنيه</p>
                    </Stack>
                </Stack>
                <Button component={Link} to='/contact' className='btn-primary' sx={{ backgroundColor: '#050' }}>تواصل معنا</Button>
            </Stack>
        </Stack>
    )
}
