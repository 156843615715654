import ResponsiveGallery from 'react-responsive-gallery';
import React from 'react'

import img1 from '../../assets/images/gallery/gallery1.jpg'
import img2 from '../../assets/images/gallery/gallery2.jpg'
import img3 from '../../assets/images/gallery/gallery3.jpg'
import img4 from '../../assets/images/gallery/gallery4.jpg'
import img5 from '../../assets/images/gallery/gallery5.jpg'
import img6 from '../../assets/images/gallery/gallery6.jpg'
import img7 from '../../assets/images/gallery/gallery7.jpg'
import img8 from '../../assets/images/gallery/gallery8.jpg'
import img9 from '../../assets/images/gallery/gallery9.jpg'
import img10 from '../../assets/images/gallery/gallery10.jpg'
import img11 from '../../assets/images/gallery/gallery11.jpg'
import img12 from '../../assets/images/gallery/gallery12.jpg'
import { Stack } from '@mui/material';

function ImagesGallery() {
    const media1 = [
        { src: img1 },
        { src: img2 },
        { src: img3 },
        { src: img4 },
        { src: img5 },
    ];

    const media2 = [
        { src: img6 },
        { src: img7 },
        { src: img8 },
        { src: img9 },
        { src: img10 },
        { src: img11 },
        { src: img12 },
    ];

    return (
        <div>
            <Stack className='gallery-images-container'>
                <h3>صور (درون)</h3>
                <ResponsiveGallery useLightBox media={media1} />
            </Stack>

            <Stack className='gallery-images-container'>
                <h3>صور إنشائات</h3>
                <ResponsiveGallery useLightBox media={media2} />
            </Stack>
        </div>
    )
}
export default ImagesGallery