import React, { useState } from 'react'
import ImagesGallery from './ImagesGallery';
import VideosGallery from './VideosGallery';

import './gallery.css'
import { Button } from '@mui/material';
import SupportForm from '../../components/SupportForm';

export default function Gallery() {
    const [content, setContent] = useState('images')
    return (
        <>
            <div className='gallery-container'>
                <div className='gallery-menu-items'>
                    <Button onClick={() => setContent('images')} className={content == 'images' ? 'active-gallery-btn' : 'gallery-btn'} variant={content == 'images' ? 'contained' : 'outlined'}>الصور</Button>
                    <Button onClick={() => setContent('videos')} className={content !== 'images' ? 'active-gallery-btn' : 'gallery-btn'} variant={content !== 'images' ? 'contained' : 'outlined'}>مقاطع الفيديوهات</Button>
                </div>
                {content == 'images' ?
                    <ImagesGallery />
                    :
                    <VideosGallery />
                }
            </div>
            <SupportForm />

        </>
    )
}
