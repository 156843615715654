import React, { useState } from 'react'
import 'react-html5video/dist/styles.css'

import CloseIcon from '@mui/icons-material/Close';

import vid1 from '../../assets/videos/vid1.mp4'
import vid2 from '../../assets/videos/vid2.mp4'
import vid3 from '../../assets/videos/rakan-mall-3D.mp4'
import vid4 from '../../assets/videos/vid4.mp4'
import poster1 from '../../assets/videos/poster-drown.jpg'
import poster2 from '../../assets/videos/poster-2.jpg'
import poster3 from '../../assets/videos/poster-3D.jpg'
import poster4 from '../../assets/videos/poster-4.jpg'

import { IconButton } from '@mui/material'

function VideosGallery() {
    const [modal, setModal] = useState(false)
    const [currentVid, setCurrentVid] = useState(null)

    console.log(modal);

    let data = [
        { id: 1, videoUri: vid1, poster: poster1, title: '(درون) توضيحي للموقع' },
        { id: 3, videoUri: vid3, poster: poster3, title: 'راكان مول (ثري دي)' },
        { id: 2, videoUri: vid2, poster: poster2, title: 'إنشائات من الموقع ' },
        { id: 4, videoUri: vid4, poster: poster4, title: 'صوتي' },
    ]

    const playVid = (vid) => {
        setCurrentVid(vid)
        setModal(true)
    }

    return (
        <div>
            <h1>معرض الفيديوهات</h1>
            <div className="video-gallery">
                {data.map((vid, i) => (
                    <div key={i} className="video">
                        <p className='title'>فيديو: {vid.title}</p>
                        <div className="video-container" onClick={() => playVid(vid)}>
                            <video
                                style={{ width: '100%' }}
                                autoPlay={currentVid == vid}
                                controls={currentVid == vid}
                                poster={vid.poster}
                            >
                                <source src={vid.videoUri} type='video/webm' />
                            </video>
                        </div>
                    </div>
                )
                )}

                {modal &&
                    (<div className="main-modal">
                        <IconButton className='modal-close-btn' onClick={() => setModal(false)}><CloseIcon /></IconButton>
                        <div className="video-container">
                            <video
                                style={{ height: 'auto', width: '100%' }}
                                autoPlay={true}
                                controls
                                poster={currentVid.poster}
                            >
                                <source src={currentVid.videoUri} type='video/webm' />
                            </video>
                        </div>
                    </div>)
                }
            </div>
        </div>
    )
}

export default VideosGallery