import { Button, Paper, Stack } from '@mui/material'
import React from 'react'

import doneIcon from '../assets/icons/done.svg'

export default function ContactSuccess() {
    return (
        <Stack sx={{ height: '100vh', width: '100%', bgcolor: 'white' }} alignItems='center' justifyContent='center'>
            <Stack sx={{ padding: '24px', textAlign: 'center' }} spacing={3}>
                <div><img src={doneIcon} alt="icon" /></div>
                <h3> تم إرسال طلبك و سيتم الرد في اسرع وقت </h3>
                <Button className='btn-primary' variant='contained'><a style={{ color: '#000' }} href="/">العودة للصفحة الرئيسية</a></Button>
            </Stack>
        </Stack>
    )
}
