import React from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';

export default function SupportForm() {

    return (
        <>
            <div style={{ position: 'fixed', bottom: '16px', right: '16px' }}>
                <Link to='http://wa.me/+201289938888' target="blanket">
                    <IconButton className='support-icon'>
                        <WhatsAppIcon />
                    </IconButton>
                </Link>
            </div>
        </>
    );
}

