import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// my imports
import logo from '../assets/images/logo.svg'
import { Link, useLocation } from 'react-router-dom';
import { Stack } from '@mui/material';

const drawerWidth = 240;

function Navbar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation().pathname

    const navbarLinks = [
        { title: 'الرئيسية', path: '/' },
        { title: 'التفاصيل', path: '/details' },
        { title: 'المعرض', path: '/gallery' },
        { title: 'تواصل معنا', path: '/contact', isBtn: true },
    ]

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <div className="logo">
                <Link to='/'><img height='100px' src={logo} alt="logo" /></Link>
            </div>
            <Divider />
            <List>
                {navbarLinks.map((navLink, index) => (
                    // <Link key={index} className={location == navLink.path ? 'nav-link activeLink' : 'nav-link'} to={navLink.path}>{navLink.title}</Link>
                    <Link style={{ color: location == navLink.path ? 'gold' : 'black' }} to={navLink.path}>
                        <ListItem disablePadding>
                            <ListItemButton sx={{ textAlign: 'center' }}>
                                <ListItemText primary={navLink.title} />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                ))}
            </List>
        </Box>
    );


    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <AppBar component="nav" sx={{ bgcolor: '#F79E02' }} >
                <Toolbar sx={{ justifyContent: 'space-between', padding: { xm: '0 24px', md: ' 0 70px !important' } }}>
                    <div style={{ height: '80px' }}>
                        <Link to='/'><img height='80px' src={logo} alt="logo" /></Link>
                    </div>
                    <div className='navbar-hidden-desctop' style={{}} >
                        {navbarLinks.map((navLink, index) => (
                            <>
                                {navLink.isBtn ? <Button component={Link} to='/contact' className={location == '/contact' ? 'nav-btn activeBtn' : 'nav-btn'}> تواصل معنا </Button> :
                                    <Link key={index} className={location == navLink.path ? 'nav-link activeLink' : 'nav-link'} to={navLink.path}>{navLink.title}</Link>
                                }
                            </>
                        ))}


                    </div>
                    <IconButton
                        color="black"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon sx={{ fontSize: '32px' }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>

        </>
    );
}

Navbar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default Navbar;