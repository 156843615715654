import { Stack } from '@mui/material'
import React from 'react'

export default function Input({ label, type, name, onChange }) {
    const styles = {
        input: {
            padding: '12px',
            outlineColor: '#F79E02',
            resize: 'none',
        }
    }
    return (
        <Stack>
            <label htmlFor={label}>{label}</label>
            <input style={styles.input} id={label} name={name} type={type} placeholder={label} required />
        </Stack>
    )
}
